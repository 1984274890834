<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <div class="text-center my-5">
                <span class="page-heading">確認メールを送信しました。</span>
              </div>
              <div class="form-header mb-4">
                {{ $t("password_reset_success_message") }}
              </div>
              <div class="message-block pa-3">
                {{ $t("password_reset_success_message_block") }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordPage",
  components: {},
  data() {
    return {
      email: ""
    };
  },
  methods: {}
};
</script>

<style scoped>
.form-header {
  color: #555555 !important;
}
.submit-btn {
  font-size: 16px !important;
  color: #333 !important;
  font-weight: 700;
  height: 50px !important;
}
.page-heading {
  color: #555;
  font-weight: 600;
  font-size: 15px;
}
.message-block {
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #858585;
  font-size: 12px;
}
</style>
