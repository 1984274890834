<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="pt-0">
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <div class="form-header mb-4">
                    {{ $t("password_reset_message") }}
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="メールアドレス"
                    rules="required|email"
                  >
                    <div class="form-header mb-2">
                      {{ $t("enter_your_registered_email") }}
                    </div>
                    <input-email
                      v-model="email"
                      :label="$t('enter_your_registered_email')"
                      placeholder="メールアドレス"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="pt-4">
                  <v-btn
                    color="#F2C94C"
                    class="submit-btn"
                    block
                    type="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                    @click="submit"
                  >
                    {{ $t("submit") }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import { mapGetters } from "vuex";
export default {
  name: "ForgotPasswordPage",
  components: { InputEmail },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        let email = this.email;
        let reset_url = `${process.env.VUE_APP_DEV_URL}/reset-password/`;

        this.$store
          .dispatch("AUTH_FORGOT", { email, reset_url })
          .then(
            response => {
              if (response.status === 200) {
                this.$router.push({
                  query: { success: true }
                });
              }
            },
            error => {
              console.error(error);
            }
          )
          .catch(err => console.log(err));
      });
    }
  }
};
</script>

<style scoped>
.form-header {
  color: #555555 !important;
}
.submit-btn {
  font-size: 16px !important;
  color: #333 !important;
  font-weight: 700;
  height: 50px !important;
}
</style>
