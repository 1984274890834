<template>
  <div>
    <div v-if="success">
      <ForgotPasswordSuccessPage />
    </div>
    <div v-else>
      <ForgotPasswordPage />
    </div>
  </div>
</template>

<script>
import ForgotPasswordPage from "@/components/layout/auth/ForgotPasswordPage";
import ForgotPasswordSuccessPage from "@/components/layout/auth/ForgotPasswordSuccessPage";
export default {
  name: "ForgotPassword",
  components: { ForgotPasswordPage, ForgotPasswordSuccessPage },
  props: {
    success: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped></style>
